import {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {addPropsToChildren} from '../../utils/view-helpers/view-helpers';
import {getPreviousPath} from '../../utils/url-utils';
import {assignParentUrl} from '../../utils/iframe-helpers';
import {UserRecoverableError} from '../../utils/user-recoverable-error';

import AlertMessageBanner from './alert-message-banner';
import BackButton from './back-button/back-button';
import ReloadDataButton from './reload-data-button';

const StyledErrorBoundary = styled.div`
    min-height: 300px;
    padding-top: 50px;
`;

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false
        };
    }

    componentDidCatch(error) {
        this.setState({
            error
        });

        console.error('Error caught in error-boundary.js', {
            error
        });
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {children, customer, ...props} = this.props;

        if (this.state.error) {
            const {error} = this.state;

            const isUserRecoverableError = error instanceof UserRecoverableError;
            const message = isUserRecoverableError
                ? "This page didn't load correctly - Try refreshing the page or going back in your browser. If the issue persists, please contact support."
                : 'An error has occurred.';

            return (
                <StyledErrorBoundary>
                    {isUserRecoverableError ? (
                        <BackButton
                            buttonLabel="Back"
                            onClick={() =>
                                getPreviousPath(window.location.href)
                                    ? assignParentUrl(getPreviousPath(window.location.href))
                                    : window.history.back()
                            }
                            paddingLeft="70px"
                            size="medium"
                        />
                    ) : null}
                    <AlertMessageBanner pageContext>
                        <span>
                            {message}
                            <ReloadDataButton />
                        </span>
                    </AlertMessageBanner>
                </StyledErrorBoundary>
            );
        }

        return addPropsToChildren(children, props);
    }
}

const mapStateToProps = (state) => ({
    customer: state.customer
});

export default connect(mapStateToProps)(ErrorBoundary);
